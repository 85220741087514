import { create } from "@bufbuild/protobuf";
import { Flex, Heading, Separator, Text } from "@radix-ui/themes";
import { Suspense, useCallback, useReducer } from "react";
import { useParams } from "react-router";
import { SchemaModelSchema } from "services/api/schemaservice/schema_pb.js";
import { StatelyError } from "services/client/errors";
import { SchemaID } from "services/data/data";
import { get, listAuditLog } from "services/schema";
import { useSchemaClient } from "services/schema/useSchemaClient";
import { useStoreInfo } from "state/user/hooks";
import { Store } from "state/user/types";
import { clear, suspend } from "suspend-react";
import ErrorBoundary from "ui/errors/ErrorBoundary";
import TerminalSnippet from "ui/shared/TerminalSnippet";
import AuditLogEntry from "./AuditLogEntry";
import SchemaContent from "./SchemaContent";

const schemaCache = (schemaId: SchemaID) => ["getSchema", schemaId];
const auditLogCache = (schemaId: SchemaID) => ["listAuditLog", schemaId];

export default function SchemaViewer() {
  const { storeId } = useParams<{ storeId: string }>();
  const storeID = BigInt(storeId ?? "0");
  const store = useStoreInfo(storeID);

  const [_reloadCount, triggerRender] = useReducer((x: number) => x + 1, 0);

  const onRollbackSuccess = useCallback(() => {
    clear(schemaCache(storeID));
    clear(auditLogCache(storeID));
    triggerRender();
  }, [storeID]);

  return (
    <Flex gap="4" direction="column" my="4">
      <Heading size="7" trim="end">
        Manage Schema
      </Heading>
      {!store ? (
        <Text>Store not found</Text>
      ) : (
        <Viewer store={store} onRollbackSuccess={onRollbackSuccess} />
      )}
    </Flex>
  );
}

export function Viewer({
  store,
  onRollbackSuccess,
}: {
  store: Store;
  onRollbackSuccess: () => void;
}) {
  const header = (
    <>
      <Heading size="2" trim="end">
        {`Stores / ${store.name}`}
      </Heading>
      <Text size="1">Store ID: {store.storeId.toString()}</Text>
      <Separator size="4" />
    </>
  );

  if (store.schemaId === 0n) {
    return (
      <>
        {header}
        <Text>
          This store does not have a schema yet. Create + Bind one with{" "}
          <a href="https://stately.cloud/downloads">the Stately CLI</a>:
          <TerminalSnippet language="cli">
            {`// create a schema under a project (if you haven't already), this will return a schema ID
stately schema create --project-id ${store.projectId.toString()} -n "My Schema" -d "My schema description"

// bind the schema to the store`}
            {`stately schema bind --schema-id schema-id --store-id ${store.storeId.toString() ?? "store-id"}`}
          </TerminalSnippet>
        </Text>
      </>
    );
  }

  return (
    <>
      {header}
      <Flex gap="4" direction="column">
        <Heading size="4" trim="end">
          Schema ID: {store.schemaId.toString()}
        </Heading>
        <ErrorBoundary name="Schema">
          <Suspense fallback={<Text>Loading schema...</Text>}>
            <SchemaPanel schemaId={store.schemaId} />
          </Suspense>
        </ErrorBoundary>
      </Flex>
      <Separator size="4" />

      <Flex gap="4" direction="column">
        <Heading size="4" trim="end">
          Audit Log
        </Heading>
        <ErrorBoundary name="AuditLog">
          <Suspense fallback={<Text>Loading audit log...</Text>}>
            <AuditLogPanel schemaId={store.schemaId} onRollbackSuccess={onRollbackSuccess} />
          </Suspense>
        </ErrorBoundary>
      </Flex>
    </>
  );
}

function SchemaPanel({ schemaId }: { schemaId: SchemaID }) {
  const client = useSchemaClient();

  const schema = suspend(async () => {
    try {
      return await get(client, schemaId);
    } catch (e) {
      if (e instanceof StatelyError && e.statelyCode === "SchemaNotFound") {
        return create(SchemaModelSchema, {});
      }
    }
  }, schemaCache(schemaId));

  if (!schema?.fileDescriptor) {
    return (
      <Text>
        This schema is empty! Put an initial schema version with{" "}
        <a href="https://stately.cloud/downloads">the Stately CLI</a>:
        <TerminalSnippet language="cli">
          {`stately schema put --schema-id ${schemaId.toString() ?? "schema-id"} my-schema/index.ts`}
        </TerminalSnippet>
      </Text>
    );
  } else {
    return <SchemaContent schema={schema} />;
  }
}

function AuditLogPanel({
  schemaId,
  onRollbackSuccess,
}: {
  schemaId: SchemaID;
  onRollbackSuccess: () => void;
}) {
  const client = useSchemaClient();
  const auditLog = suspend(() => listAuditLog(client, schemaId), auditLogCache(schemaId));

  if (auditLog.length === 0) {
    return <Text>No audit log found for the schema</Text>;
  } else {
    return auditLog.map((entry, i) => (
      <AuditLogEntry
        key={i}
        entry={entry}
        showRollbackButton={i > 0}
        onRollbackSuccess={onRollbackSuccess}
      />
    ));
  }
}
