// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/put.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_protobuf_descriptor } from "@bufbuild/protobuf/wkt";
import { file_schemaservice_validate } from "./validate_pb.js";

/**
 * Describes the file schemaservice/put.proto.
 */
export const file_schemaservice_put =
  /*@__PURE__*/
  fileDesc(
    "ChdzY2hlbWFzZXJ2aWNlL3B1dC5wcm90bxIVc3RhdGVseS5zY2hlbWFzZXJ2aWNlIrcBCgpQdXRSZXF1ZXN0EhEKCXNjaGVtYV9pZBgGIAEoBBI9Cg9maWxlX2Rlc2NyaXB0b3IYAiABKAsyJC5nb29nbGUucHJvdG9idWYuRmlsZURlc2NyaXB0b3JQcm90bxIPCgdkcnlfcnVuGAMgASgIEhoKEmNoYW5nZV9kZXNjcmlwdGlvbhgEIAEoCRIkChxhbGxvd19iYWNrd2FyZHNfaW5jb21wYXRpYmxlGAUgASgISgQIARACIn8KC1B1dFJlc3BvbnNlEkIKEXZhbGlkYXRlX3Jlc3BvbnNlGAEgASgLMicuc3RhdGVseS5zY2hlbWFzZXJ2aWNlLlZhbGlkYXRlUmVzcG9uc2USEQoJY29tbWl0dGVkGAIgASgIEhkKEXNjaGVtYV92ZXJzaW9uX2lkGAMgASgNQpoBChljb20uc3RhdGVseS5zY2hlbWFzZXJ2aWNlQghQdXRQcm90b1ABogIDU1NYqgIVU3RhdGVseS5TY2hlbWFzZXJ2aWNlygIVU3RhdGVseVxTY2hlbWFzZXJ2aWNl4gIhU3RhdGVseVxTY2hlbWFzZXJ2aWNlXEdQQk1ldGFkYXRh6gIWU3RhdGVseTo6U2NoZW1hc2VydmljZWIGcHJvdG8z",
    [file_google_protobuf_descriptor, file_schemaservice_validate],
  );

/**
 * Describes the message stately.schemaservice.PutRequest.
 * Use `create(PutRequestSchema)` to create a new message.
 */
export const PutRequestSchema = /*@__PURE__*/ messageDesc(file_schemaservice_put, 0);

/**
 * Describes the message stately.schemaservice.PutResponse.
 * Use `create(PutResponseSchema)` to create a new message.
 */
export const PutResponseSchema = /*@__PURE__*/ messageDesc(file_schemaservice_put, 1);
