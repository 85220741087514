import "@redux-devtools/extension";
import { StoreID } from "services/data/data";
import { createStore, deleteStore } from "services/management";
import type { OrganizationID, ProjectID, WhoamiResponse } from "services/user";
import { loadUser } from "services/user/load-user";
import { convertToError } from "utils/errors";
import { dedupePromise } from "utils/promises";
import { reportException } from "utils/sentry";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Organization, Project, Store, User, UserState } from "./types";

/**
 * The User store includes information about the user and their resources (orgs, projects, stores, etc).
 *
 * This shouldn't be used directly - it should be consumed via selector hooks from ./selectors.ts.
 */
// For now, we'll use separate stores for users and other types of data. Maybe in the future we combine them with https://github.com/pmndrs/zustand/blob/main/docs/guides/slices-pattern.md
// We could use Zustand's 'persist' middleware, but it's easier on us if we just save/restore the API response directly, rather than the transformed state.
export const useUserStore = create<UserState>()(
  devtools(
    (set, get) => ({
      user: undefined,
      projects: new Map(),
      organizations: new Map(),
      stores: new Map(),
      whoamiLoaded: false,

      loadUser: dedupePromise(async (userClient) => {
        if (get().whoamiLoaded) {
          return;
        }
        try {
          const whoami = await loadUser(userClient);
          set(
            { ...normalizeWhoami(whoami), whoamiError: undefined, whoamiLoaded: true },
            undefined,
            // This extra info is just so it shows up nicely in Redux DevTools
            {
              type: "whoamiLoaded",
              whoami,
            },
          );
        } catch (err) {
          reportException("whoami", err);
          set({ whoamiError: convertToError(err), whoamiLoaded: true }, undefined, {
            type: "whoamiError",
            err,
          });
        }
      }),

      createStore: async (managementClient, region, projectId, name, description) => {
        const result = await createStore(managementClient, region, projectId, name, description);
        const store: Store = {
          storeId: result.storeId,
          name,
          description,
          schemaId: 0n,
          projectId,
          defaultRegion: region,
        };
        set({ stores: new Map(get().stores).set(store.storeId, store) }, undefined, {
          type: "createStore",
          result,
        });
        return store.storeId;
      },

      deleteStore: async (managementClient, region, storeId) => {
        await deleteStore(managementClient, region, storeId);
        const newStores = new Map(get().stores);
        newStores.delete(storeId);
        set({ stores: newStores }, undefined, {
          type: "deleteStore",
          storeId,
        });
      },
    }),
    // Configure Redux DevTools integration
    {
      serialize: {
        // It can't handle bigints
        replacer: (_key, value) => (typeof value === "bigint" ? value.toString() : value),
      },
      enabled: $featureFlags.reduxDevTools,
    },
  ),
);

/**
 * We save a normalized version of the whoami response to make it easier to have
 * a single source of truth per item, modify results immutably and build
 * selectors.
 */
function normalizeWhoami(
  whoami: WhoamiResponse | undefined,
): Pick<UserState, "user" | "projects" | "organizations" | "stores"> {
  const allOrganizations = new Map<OrganizationID, Organization>();
  const allProjects = new Map<ProjectID, Project>();
  const allStores = new Map<StoreID, Store>();
  let user: User | undefined;

  if (whoami) {
    const { organizations, ...userData } = whoami ?? {};
    user = userData;
    for (const { organization, projects } of organizations) {
      if (organization) {
        allOrganizations.set(organization.organizationId, organization);
        for (const { project, stores } of projects) {
          if (project) {
            allProjects.set(project.projectId, project);
            for (const { store } of stores) {
              if (store) {
                allStores.set(store.storeId, { ...store, projectId: project.projectId });
              }
            }
          }
        }
      }
    }
  }

  return {
    organizations: allOrganizations,
    projects: allProjects,
    stores: allStores,
    user,
  };
}
