// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/service.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_user_add_users_to_organization } from "./add_users_to_organization_pb.js";
import { file_user_create_organization } from "./create_organization_pb.js";
import { file_user_create_project } from "./create_project_pb.js";
import { file_user_delete_organization } from "./delete_organization_pb.js";
import { file_user_delete_project } from "./delete_project_pb.js";
import { file_user_delete_user } from "./delete_user_pb.js";
import { file_user_enroll } from "./enroll_pb.js";
import { file_user_get_caller_identity } from "./get_caller_identity_pb.js";
import { file_user_list_all_organizations } from "./list_all_organizations_pb.js";
import { file_user_remove_users_from_organization } from "./remove_users_from_organization_pb.js";
import { file_user_whoami } from "./whoami_pb.js";

/**
 * Describes the file user/service.proto.
 */
export const file_user_service =
  /*@__PURE__*/
  fileDesc(
    "ChJ1c2VyL3NlcnZpY2UucHJvdG8SDHN0YXRlbHkudXNlcjLZCQoLVXNlclNlcnZpY2USSAoGV2hvYW1pEhsuc3RhdGVseS51c2VyLldob2FtaVJlcXVlc3QaHC5zdGF0ZWx5LnVzZXIuV2hvYW1pUmVzcG9uc2UiA5ACARJpChFHZXRDYWxsZXJJZGVudGl0eRImLnN0YXRlbHkudXNlci5HZXRDYWxsZXJJZGVudGl0eVJlcXVlc3QaJy5zdGF0ZWx5LnVzZXIuR2V0Q2FsbGVySWRlbnRpdHlSZXNwb25zZSIDkAIBEkgKBkVucm9sbBIbLnN0YXRlbHkudXNlci5FbnJvbGxSZXF1ZXN0Ghwuc3RhdGVseS51c2VyLkVucm9sbFJlc3BvbnNlIgOQAgISVAoKRGVsZXRlVXNlchIfLnN0YXRlbHkudXNlci5EZWxldGVVc2VyUmVxdWVzdBogLnN0YXRlbHkudXNlci5EZWxldGVVc2VyUmVzcG9uc2UiA5ACAhJpChFFbnJvbGxNYWNoaW5lVXNlchImLnN0YXRlbHkudXNlci5FbnJvbGxNYWNoaW5lVXNlclJlcXVlc3QaJy5zdGF0ZWx5LnVzZXIuRW5yb2xsTWFjaGluZVVzZXJSZXNwb25zZSIDkAICEloKDUNyZWF0ZVByb2plY3QSIi5zdGF0ZWx5LnVzZXIuQ3JlYXRlUHJvamVjdFJlcXVlc3QaIy5zdGF0ZWx5LnVzZXIuQ3JlYXRlUHJvamVjdFJlc3BvbnNlIgASXQoNRGVsZXRlUHJvamVjdBIiLnN0YXRlbHkudXNlci5EZWxldGVQcm9qZWN0UmVxdWVzdBojLnN0YXRlbHkudXNlci5EZWxldGVQcm9qZWN0UmVzcG9uc2UiA5ACAhJpChJDcmVhdGVPcmdhbml6YXRpb24SJy5zdGF0ZWx5LnVzZXIuQ3JlYXRlT3JnYW5pemF0aW9uUmVxdWVzdBooLnN0YXRlbHkudXNlci5DcmVhdGVPcmdhbml6YXRpb25SZXNwb25zZSIAEnIKFExpc3RBbGxPcmdhbml6YXRpb25zEikuc3RhdGVseS51c2VyLkxpc3RBbGxPcmdhbml6YXRpb25zUmVxdWVzdBoqLnN0YXRlbHkudXNlci5MaXN0QWxsT3JnYW5pemF0aW9uc1Jlc3BvbnNlIgOQAgESbAoSRGVsZXRlT3JnYW5pemF0aW9uEicuc3RhdGVseS51c2VyLkRlbGV0ZU9yZ2FuaXphdGlvblJlcXVlc3QaKC5zdGF0ZWx5LnVzZXIuRGVsZXRlT3JnYW5pemF0aW9uUmVzcG9uc2UiA5ACAhJ4ChZBZGRVc2Vyc1RvT3JnYW5pemF0aW9uEisuc3RhdGVseS51c2VyLkFkZFVzZXJzVG9Pcmdhbml6YXRpb25SZXF1ZXN0Giwuc3RhdGVseS51c2VyLkFkZFVzZXJzVG9Pcmdhbml6YXRpb25SZXNwb25zZSIDkAICEocBChtSZW1vdmVVc2Vyc0Zyb21Pcmdhbml6YXRpb24SMC5zdGF0ZWx5LnVzZXIuUmVtb3ZlVXNlcnNGcm9tT3JnYW5pemF0aW9uUmVxdWVzdBoxLnN0YXRlbHkudXNlci5SZW1vdmVVc2Vyc0Zyb21Pcmdhbml6YXRpb25SZXNwb25zZSIDkAICQnEKEGNvbS5zdGF0ZWx5LnVzZXJCDFNlcnZpY2VQcm90b1ABogIDU1VYqgIMU3RhdGVseS5Vc2VyygIMU3RhdGVseVxVc2Vy4gIYU3RhdGVseVxVc2VyXEdQQk1ldGFkYXRh6gINU3RhdGVseTo6VXNlcmIGcHJvdG8z",
    [
      file_user_add_users_to_organization,
      file_user_create_organization,
      file_user_create_project,
      file_user_delete_organization,
      file_user_delete_project,
      file_user_delete_user,
      file_user_enroll,
      file_user_get_caller_identity,
      file_user_list_all_organizations,
      file_user_remove_users_from_organization,
      file_user_whoami,
    ],
  );

/**
 * User service is used to manage users, organizations, teams, projects, etc.
 *
 * @generated from service stately.user.UserService
 */
export const UserService = /*@__PURE__*/ serviceDesc(file_user_service, 0);
