// @generated by protoc-gen-es v2.1.0 with parameter "target=js+dts,import_extension=.js"
// @generated from file errors/error_details.proto (package stately.errors, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file errors/error_details.proto.
 */
export const file_errors_error_details =
  /*@__PURE__*/
  fileDesc(
    "ChplcnJvcnMvZXJyb3JfZGV0YWlscy5wcm90bxIOc3RhdGVseS5lcnJvcnMiVAoTU3RhdGVseUVycm9yRGV0YWlscxIUCgxzdGF0ZWx5X2NvZGUYASABKAkSDwoHbWVzc2FnZRgCIAEoCRIWCg51cHN0cmVhbV9jYXVzZRgDIAEoCUKAAQoSY29tLnN0YXRlbHkuZXJyb3JzQhFFcnJvckRldGFpbHNQcm90b1ABogIDU0VYqgIOU3RhdGVseS5FcnJvcnPKAg5TdGF0ZWx5XEVycm9yc+ICGlN0YXRlbHlcRXJyb3JzXEdQQk1ldGFkYXRh6gIPU3RhdGVseTo6RXJyb3JzYgZwcm90bzM",
  );

/**
 * Describes the message stately.errors.StatelyErrorDetails.
 * Use `create(StatelyErrorDetailsSchema)` to create a new message.
 */
export const StatelyErrorDetailsSchema = /*@__PURE__*/ messageDesc(file_errors_error_details, 0);
