import { Flex } from "@radix-ui/themes";
import { useStoresForProject } from "state/user/hooks";
import { Project } from "state/user/types";
import { StoreTile } from "ui/store/StoreTile";

export function ProjectSection({ project }: { project: Project }) {
  const stores = useStoresForProject(project.projectId);
  return (
    <>
      <Flex gap="4" direction="column" ml="3">
        {stores.map((store) => (
          <StoreTile key={store.storeId.toString()} store={store} />
        ))}
      </Flex>
    </>
  );
}
