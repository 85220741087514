import { faDatabase, faShapes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Flex, Heading, Text, Tooltip } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import { Region } from "services/api/dbmanagement/region_pb.js";
import { Store } from "state/user/types";
import TerminalSnippet from "ui/shared/TerminalSnippet";
import { regionToString } from "utils/region";
import styles from "./StoreTile.m.css";

export function StoreTile({ store }: { store: Store }) {
  return (
    <Card>
      <Flex direction="column" gap="4">
        <Flex direction="row" justify="between">
          <Flex direction="column" gap="2">
            <Heading size="4" trim="end">
              <FontAwesomeIcon icon={faDatabase} /> {store.name}
            </Heading>
            <Text>
              Store ID: {store.storeId.toString()}, Region: {Region[store.defaultRegion]}
            </Text>
          </Flex>
          <Flex direction="row" gap="4">
            {/* <Link id="data-browser-link" to={`/store/${store.storeId}/browse`}>
              <Button variant="solid">
                <FontAwesomeIcon icon={faTable} />
                Explore Items
              </Button>
            </Link> */}
            <Link id="schema-link" to={`/store/${store.storeId}/schema`}>
              <Button variant="solid">
                <FontAwesomeIcon icon={faShapes} />
                Manage Schema
              </Button>
            </Link>
            <Tooltip
              delayDuration={0}
              content="Contact support@stately.cloud for help with your Store settings."
            >
              <Button variant="solid" disabled>
                <FontAwesomeIcon icon={faDatabase} />
                Manage Store
              </Button>
            </Tooltip>
          </Flex>
        </Flex>

        <details>
          <summary>Client setup instructions</summary>
          <div className={styles.codeExamples}>
            <Text>
              Check out our <a href="https://docs.stately.cloud">documentation</a> for more details.
            </Text>
            <TerminalSnippet language="JavaScript">
              {`// Import your generated client
import { createClient } from './schema';
const client = createClient(${store.storeId.toString()}n, { region: "${regionToString(store.defaultRegion)}" });`}
            </TerminalSnippet>
            <TerminalSnippet language="Go">
              {`package client

// Import your generated client
import (
  // Import your generated schema code
  "github.com/myorg/myrepo/generated/schema"
)

func createClient() (stately.Client, error) {
  client, err := schema.NewClient(ctx, stately.StoreID(${store.storeId.toString()}), &stately.Options{ Region: "${regionToString(store.defaultRegion)}" })
  return client, err
}`}
            </TerminalSnippet>
            <TerminalSnippet language="Ruby">
              {`# Import your generated client
require_relative 'schema/stately.item_types'

client = StatelyDB::Client.new(store_id: ${store.storeId.toString()}), region: "${regionToString(store.defaultRegion)}")`}
            </TerminalSnippet>
            <TerminalSnippet language="Python">
              {`# Import your generated client
from schema import Client

client = Client(store_id=${store.storeId.toString()}), region="${regionToString(store.defaultRegion)}")`}
            </TerminalSnippet>
          </div>
        </details>
      </Flex>
    </Card>
  );
}
